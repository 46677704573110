<script setup lang="ts">
import { useGtm } from "@gtm-support/vue-gtm";

const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();
const { controller } = toRefs(props);
const { isOpen, toggle, close } = controller.value;

const { cartItems, isEmpty, totalPrice } = useCart();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { count, isShipmentSpedi } = useCart();
const { isLoggedIn } = useUser();
const { lineItemsToTrackingItems } = await useTrackingHelper();
const gtm = useGtm();
const { getFormattedPrice } = usePrice();

watch(isOpen, async (open) => {
  const body = window?.document?.body;
  if (body) body.classList.toggle("overflow-hidden", open);

  if (!open) return;

  // track cart view
  const items = await lineItemsToTrackingItems(cartItems.value);
  const value = items.reduce((p, q) => p + (q.price || 0), 0);
  gtm?.push({ ecommerce: null });
  gtm?.push({
    event: "view_cart",
    ecommerce: {
      currency: useShopCurrency(),
      value,
      items,
    },
  });
});
</script>

<template>
  <div
    tabindex="0"
    class="size-10 xl:size-12.5 cursor-pointer relative"
    :class="{ 'custom-underline': isOpen }"
    @click="toggle"
  >
    <div class="h-full mx-auto w-5 xl:w-6 i-sl-shopping-cart-empty"></div>
    <span
      v-if="count"
      class="c-scheppach-primary-700 size-4 block rd-2 top-1.5 xl:top-3 right-4 absolute text-3 lh-4 font-700"
      :class="{ 'right-4': count > 9 }"
    >
      {{ count }}
    </span>
  </div>
  <LayoutSidebar
    :controller="controller"
    side="right"
    layer-class="z-1500 xl:z-10"
    class="bg-scheppach-primary-50! flex overflow-auto"
  >
    <div
      class="mb-5 xl:mb-11 xl:mt-22 p-10 bg-[#336d9d] flex c-scheppach-shades-0 items-center text-left"
    >
      <div class="text-5 xl:text-6 font-700 uppercase flex-grow">
        {{ $t("cart.header") }}
      </div>
      <button
        type="button"
        class="size-4 xl:size-5 i-sl-close cursor-pointer"
        @click="close"
      ></button>
    </div>
    <div class="px-5 xl:px-10.5">
      <aside class="flex-shrink overflow-y-auto overflow-x-hidden">
        <template v-if="!isEmpty">
          <ul role="list" class="flex flex-col gap-2 p-0 m-0 relative">
            <li v-for="cartItem in cartItems" :key="cartItem.id" class="block">
              <CheckoutCartItem :cart-item="cartItem" />
            </li>
          </ul>
          <span
            class="flex gap-2 text-5.5 c-scheppach-primary-500 mt-3 pr-1 py-0.5 items-center justify-end"
          >
            <p class="font-500">{{ $t("checkout.totalLabel") }}:</p>
            <p class="font-700 underline underline-1 underline-offset-5">
              {{ getFormattedPrice(totalPrice) }}
            </p>
          </span>
        </template>
        <div v-else class="text-2xl text-center">
          {{ $t("cart.emptyCartLabel") }}
        </div>
      </aside>

      <aside class="mx-5 sm:mx-0 mt-15 mb-8">
        <div
          v-if="count > 0"
          class="flex flex-col sm:flex-row items-center justify-center sm:justify-end gap-5"
        >
          <NuxtLink
            :to="formatLink('/checkout/cart')"
            class="c-scheppach-primary-500 font-700 underline underline-offset-5 text-[18px]"
          >
            {{ $t("cart.viewCart") }}
          </NuxtLink>

          <div>
            <NuxtLink
              v-if="isLoggedIn"
              :to="formatLink('/checkout')"
              class="inline-block bg-scheppach-primary-500 text-scheppach-shades-0 font-700 h-10 rd uppercase py-2 px-8"
            >
              {{ $t("cart.checkout") }}
            </NuxtLink>
            <NuxtLink
              v-else
              :to="formatLink('/account/login?redirect=/checkout')"
              class="inline-block bg-scheppach-primary-500 text-scheppach-shades-0 font-700 h-10 rd uppercase py-2 px-8"
            >
              {{ $t("cart.checkout") }}
            </NuxtLink>
          </div>
        </div>
      </aside>
      <aside
        v-if="!isEmpty && props.controller.isOpen && !isShipmentSpedi()"
        class="mb-10 gap-4 grid grid-cols-1 sm:grid-cols-2"
      >
        <CheckoutButtonPayPalExpress />
        <CheckoutButtonAmazonPayExpress />
      </aside>
    </div>
  </LayoutSidebar>
</template>
